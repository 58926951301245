export const moreInfoMessages = {
  phoneRequired:
    'By providing us your mobile phone number, you agree to receive SMS text messages regarding your order status.'
};

export const additionalFormErrorMessages = {
  addressLine1: {
    hasInvalidSpecialCharacterOnAddress: 'ex: ^ * % {} ~ > @ !'
  },
  addressLine2: {
    hasInvalidSpecialCharacterOnAddress: 'ex: ^ * % {} ~ > @ !'
  },
  email: {
    hasInvalidSpecialCharacterOnEmail: 'ex.: & “ ? < > { } ~ / \\ or space',
    requireDomainName: 'ex.: @gmail, @yahoo, @companyname',
    requireTopLevelDomain: 'ex.: .com, .org, .net',
    requireValidDomain: 'ex.: domain.com, domain.org'
  }
};

export const defaultFormErrorMessages = {
  addressLine1: {
    atLeastOneNumeric: 'Please enter your full street address',
    hasInvalidSpecialCharacterOnAddress:
      'Address has an invalid special character',
    isValidAddress: 'Please enter your full street address',
    maxLength: 'Address Line 1 is too long',
    notEmpty: 'Address Line 1 cannot be empty'
  },
  addressLine2: {
    atLeastOneNumeric: 'Please enter your full street address',
    hasInvalidSpecialCharacterOnAddress:
      'Address has an invalid special character',
    isValidAddress: 'Please enter your full street address',
    maxLength: 'Address Line 2 is too long',
    notEmpty: 'Address Line 2 cannot be empty'
  },
  cardNumber: {
    isValidCreditCard: 'Number mistyped, please check again',
    notEmpty: 'Card Number cannot be empty'
  },
  city: {
    notEmpty: 'City cannot be empty'
  },
  companyName: {
    isValidName: 'Company Name is invalid',
    notEmpty: 'Company Name cannot be empty'
  },
  country: {
    isMatchingZipToCountry: "Country doesn't match your zip code",
    notEmpty: 'Country cannot be empty'
  },
  email: {
    hasInvalidSpecialCharacterOnEmail: 'Email has an invalid character',
    isValidEmail: 'Email is invalid',
    notEmpty: 'Email cannot be empty',
    requireAt: 'Email address is missing @ character',
    requireDomainName: 'Email is missing info',
    requireTopLevelDomain: 'Email is missing info',
    requireValidDomain: 'Email mistyped, please check again'
  },
  firstName: {
    isValidName: 'First Name is invalid',
    notEmpty: 'First Name cannot be empty'
  },
  fleetAccountNumber: {
    isValidFleetCompany:
      'Fleet account number not found. Please check the account number and try again. For help with you fleet account call (866) 895-8032',
    isValidName: 'Fleet Account Number is invalid',
    notEmpty: 'Fleet Account Number cannot be empty'
  },
  lastName: {
    isValidName: 'Last Name is invalid',
    notEmpty: 'Last Name cannot be empty'
  },
  link: {
    isValidLink: 'Link appears to be invalid.',
    notEmpty: 'Link to lower price is required.'
  },
  password: {
    atLeastOneNumeric: 'Password must contain a number',
    combinedError:
      'Passwords must include at least seven characters and at least one letter and one number',
    hasLowerCase: 'Password must contain upper and lower case letters',
    hasUpperCase: 'Password must contain upper and lower case letters',
    isValidPassword:
      'Passwords must include at least seven characters and at least one letter and one number',
    maxLength: 'Password must be between 7 - 40 characters',
    minLength: 'Password must be between 7 - 40 characters',
    notEmpty: 'Password cannot be empty'
  },
  phone: {
    isValidPhone: 'Phone number is invalid',
    isValidPhoneNumberLength: 'Missing number',
    notEmpty: 'Phone number cannot be empty'
  },
  phoneType: {
    notEmpty: 'Phone type cannot be empty'
  },
  price: {
    isValidPrice: 'Price is invalid.',
    notEmpty: 'Lower price is required.'
  },
  state: {
    isMatchingZipToState: "State doesn't match your zip code",
    notEmpty: 'State cannot be empty'
  },
  zip: {
    isValidPostalCode: 'Zip Code is invalid',
    notEmpty: 'Zip Code cannot be empty'
  }
};

export const APPOINTMENT_DATERANGE_REMINDER = 'APPOINTMENT_DATERANGE_REMINDER';
export const APPOINTMENT_DATERANGE_REMINDER_STORE_ORDER =
  'APPOINTMENT_DATERANGE_REMINDER_STORE_ORDER';
export const APPOINTMENT_DATERANGE_REMINDER_SERVICE_ORDER =
  'APPOINTMENT_DATERANGE_REMINDER_SERVICE_ORDER';
export const AWD_BELOW_RECOMMENDED_QUANTITY_LONG_MSG =
  'AWD_BELOW_RECOMMENDED_QUANTITY_LONG_MSG';
export const AWD_BELOW_RECOMMENDED_QUANTITY_SHORT_MSG =
  'AWD_BELOW_RECOMMENDED_QUANTITY_SHORT_MSG';
export const PAYMENT_PLANS_AVAILABLE_MSG = 'PAYMENT_PLANS_AVAILABLE_MSG';
export const PAYMENT_PLANS_MIN_AMOUNT = 'PAYMENT_PLANS_MIN_AMOUNT';
export const AT_CERTIFICATES_FULL_DESCRIPTION_MSG =
  'AT_CERTIFICATES_FULL_DESCRIPTION_MSG';
export const DT_CERTIFICATES_FULL_DESCRIPTION_MSG =
  'DT_CERTIFICATES_FULL_DESCRIPTION_MSG';
export const CERTIFICATES_NO_CHECKBOX_MSG = 'CERTIFICATES_NO_CHECKBOX_MSG';
export const CERTIFICATES_YES_CHECKBOX_MSG = 'CERTIFICATES_YES_CHECKBOX_MSG';
export const CERTIFICATES_STAGGERED_YES_CHECKBOX_MSG =
  'CERTIFICATES_STAGGERED_YES_CHECKBOX_MSG';
export const CONFIRM_STORE_MODAL_SUBTITLE = 'CONFIRM_STORE_MODAL_SUBTITLE';
export const FITMENT_LOWERED_SIZES_MESSAGE = 'FITMENT_LOWERED_SIZES_MESSAGE';
export const FITMENT_LIFTED_SIZES_MESSAGE = 'FITMENT_LIFTED_SIZES_MESSAGE';
export const FITMENT_OPTIONAL_SIZES_MESSAGE = 'FITMENT_OPTIONAL_SIZES_MESSAGE';
export const FITMENT_TRIM_NOT_FOUND_HDG = 'FITMENT_TRIM_NOT_FOUND_HDG';
export const FITMENT_TRIM_NOT_FOUND_MSG = 'FITMENT_TRIM_NOT_FOUND_MSG';
export const FITMENT_TRIM_MORE_HELP_HDG = 'FITMENT_TRIM_MORE_HELP_HDG';
export const FITMENT_TRIM_MORE_HELP_MSG = 'FITMENT_TRIM_MORE_HELP_MSG';

export const DT_FITMENT_YOU_CAN_STILL_SHOP_MESSAGE =
  'DT_FITMENT_YOU_CAN_STILL_SHOP_MESSAGE';

export const IDME_AD_BLOCKER_MSG = 'IDME_AD_BLOCKER_MSG';
export const VTV_SUMMARY_MSG = 'VTV_SUMMARY_MSG';
export const VTV_TREADDEPTHS_TIREAGE_MSG = 'VTV_TREADDEPTHS_TIREAGE_MSG';
export const VTV_APPOINTMENT_MSG = 'VTV_APPOINTMENT_MSG';
export const FITMENT_OTHER_VEHICLES_OE_MESSAGE =
  'FITMENT_OTHER_VEHICLES_OE_MESSAGE';

export const TREADWELL_WORNTIRE_TREAD_LIFE_TITLE =
  'TREADWELL_WORNTIRE_TREAD_LIFE_TITLE';
export const TREADWELL_WORNTIRE_TREAD_LIFE_PER_DOLLAR_HDR =
  'TREADWELL_WORNTIRE_TREAD_LIFE_PER_DOLLAR_HDR';
export const TREADWELL_WORNTIRE_TREAD_LIFE_ESTIMATION_HDR =
  'TREADWELL_WORNTIRE_TREAD_LIFE_ESTIMATION_HDR';
export const TREADWELL_WORNTIRE_TREAD_LIFE_YEARS_MONTHS_HDR =
  'TREADWELL_WORNTIRE_TREAD_LIFE_YEARS_MONTHS_HDR';
export const TREADWELL_WORNTIRE_TREAD_LIFE_PER_DOLLAR_MSG =
  'TREADWELL_WORNTIRE_TREAD_LIFE_PER_DOLLAR_MSG';
export const TREADWELL_WORNTIRE_TREAD_LIFE_ESTIMATION_MSG =
  'TREADWELL_WORNTIRE_TREAD_LIFE_ESTIMATION_MSG';
export const TREADWELL_WORNTIRE_TREAD_LIFE_YEARS_MONTHS_MSG =
  'TREADWELL_WORNTIRE_TREAD_LIFE_YEARS_MONTHS_MSG';
export const WIPER_SETS_TAB_MSG = 'WIPER_SETS_TAB_MSG';

export const FORGOT_PASSWORD_SUBJECT_MSG = 'FORGOT_PASSWORD_SUBJECT_MSG';
export const FORGOT_PASSWORD_HEADLINE_MSG = 'FORGOT_PASSWORD_HEADLINE_MSG';
export const FORGOT_PASSWORD_CONTENT_MSG = 'FORGOT_PASSWORD_CONTENT_MSG';
export const FORGOT_PASSWORD_EMAIL_SENT_MSG = 'FORGOT_PASSWORD_EMAIL_SENT_MSG';
export const FORGOT_PASSWORD_EMAIL_SENT_BODY_MSG =
  'FORGOT_PASSWORD_EMAIL_SENT_BODY_MSG';
export const FORGOT_PASSWORD_ERROR_SUBJECT_MESSAGE =
  'FORGOT_PASSWORD_ERROR_SUBJECT_MESSAGE';
export const FORGOT_PASSWORD_ERROR_BODY_MESSAGE =
  'FORGOT_PASSWORD_ERROR_BODY_MESSAGE';
export const TPMS_ACCESSORY_TOOLTIP_TEXT_MSG =
  'TPMS_ACCESSORY_TOOLTIP_TEXT_MSG';
export const OPTIONAL_FITMENT_CHECKOUT_ALTERNATIVES_WARNING =
  'OPTIONAL_FITMENT_CHECKOUT_ALTERNATIVES_WARNING';
export const OPTIONAL_FITMENT_CHECKOUT_WONTINSTALL_WARNING =
  'OPTIONAL_FITMENT_CHECKOUT_WONTINSTALL_WARNING';
export const CHECKOUT_PRODUCT_DETAILS_WARNING =
  'CHECKOUT_PRODUCT_DETAILS_WARNING';
export const DT_FITMENT_PRODUCT_NOT_FIT_MESSAGE =
  'DT_FITMENT_PRODUCT_NOT_FIT_MESSAGE';
export const DT_FITMENT_VEHICLE_FITMENT_REQUIRED_MESSAGE =
  'DT_FITMENT_VEHICLE_FITMENT_REQUIRED_MESSAGE';
export const DT_FITMENT_ENTER_VEHICLE_MESSAGE =
  'DT_FITMENT_ENTER_VEHICLE_MESSAGE';
export const DT_FITMENT_PRODUCT_DOES_NOT_FIT_MESSAGE =
  'DT_FITMENT_PRODUCT_DOES_NOT_FIT_MESSAGE';
export const DT_FITMENT_TIRES_NOT_FIT_MESSAGE =
  'DT_FITMENT_TIRES_NOT_FIT_MESSAGE';
export const DT_GEOLOCATING_STORE_SEARCH_EXPANDED_RADIUS =
  'DT_GEOLOCATING_STORE_SEARCH_EXPANDED_RADIUS';

export const ATC_MARKETING_MESSAGE = 'atc-marketing-message';
export const LOCATOR_MARKETING_MESSAGE = 'locator-marketing-message';
export const SDP_MARKETING_MESSAGE = 'sdp-marketing-message';
